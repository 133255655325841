<template>

  <router-view/>
</template>

<style>
@font-face {
  font-family: 'Nissan Regular';
  src: url(assets/fonts/NissanBrand-Regular.woff) format("woff"); 
  }
@font-face {
  font-family: "Nissan Light";
  src: url(assets/fonts/NissanBrand-Light.woff) format("woff");
   }
@font-face {
  font-family: "Nissan Bold";
  src: url(assets/fonts/NissanBrand-Bold.woff) format("woff");
   }
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nissan Regular";
  color: #000;
  }

body {
  background-color: #fff;
  font-family: "Nissan Regular";
   }
#app {
   font-family: "Nissan Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
