<script setup>
import InputRanges from "../Inputs/input-ranges.vue";
import InputSelect from "../Inputs/input-select.vue";
import DoubleRangeSlider from "../Inputs/RangeSlider.vue";
</script>

<template>
  <div class="container mt-5">
   

   <div>
          
      <p class="mutedText text-left">
        Filters have been applied to your search results.
        <a v-on:click="isHidden = !isHidden">Refine your search.</a>
      </p>
      <button class="searchBtn" v-on:click="isHidden = !isHidden">
        <span v-if="isHidden">REFINE YOUR SEARCH</span>
        <span v-if="!isHidden">HIDE SEARCH</span>
        <svg
          class="svg-icon"
          width="16"
          fill="c60b38"
          style="
            width: 1em;
            height: 1em;
            vertical-align: middle;
            overflow: hidden;
          "
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M533.215 609.227c-8.956-12.502-7.819-30.005 3.412-41.236L819.47 285.148l90.51 90.51c12.497 12.497 12.497 32.758 0 45.255l-282.54 282.54 0.304 0.303-90.51 90.51c-12.496 12.496-32.757 12.496-45.254 0L96 398.285l90.51-90.51c12.496-12.497 32.758-12.497 45.255 0l301.45 301.45z"
          />
        </svg>
      </button>
    </div>

    <!-- {{allSearch}} -->
    <Transition mode="out-in">
      <div v-if="!isHidden" class="mt-3">
        <div class="searchSlider">
      
          <!-- <div style="width:30%">
          <DoubleRangeSlider :min="min" :max="max"   @change-foo="changepricerange"
          label="Price"
            labeltext="max R %s"
          
          ></DoubleRangeSlider>
        </div> -->
          <InputRanges
            key="1"
            class="range"
            
            label="Price"
            labeltext="R"
            :minvalue="0"
            :maxvalue="899900"
            @change-foo="changeprice"
          />
          <InputRanges
          key="2"
            class="range"

            label="Mileage"
            labeltext="KM"
            :minvalue="0"
            :maxvalue="355000"
            @change-foo="changeMileage"

          />
          <InputRanges
          key="3"

            class="range"

            label="Model year"
            labeltext=""
            :minvalue="2016"
            :maxvalue="2023"
            @change-foo="changeYear"

          />
        </div>
        <div class="selectInputs">
          <div class="inputGroup">
              <InputSelect
              labeltext="Make"
              :options="allMakes"
              :default="'All MAke'"
              class="select"
               @change-foo="changeMake"
            />
            <InputSelect
              labeltext="Body style"
              :options="allBodys"
              :default="'All Body style'"
              class="select"
               @change-foo="changeBody"

            />
            <InputSelect
              labeltext="Model"
              :options="AllModels"
              :default="'All Model'"
              class="select"
                             @change-foo="changeModel"

            />
            <InputSelect
              labeltext="Transmission"
              :options="AllTransmission"
              :default="'All Transmissions'"
              class="select"
              @change-foo="changeTransmission"

            />
          </div>
          <div class="inputGroup">
            <!-- <InputSelect
              labeltext="Drives"
              :options="['All Drives', 'Rare', 'rust', 'javascript']"
              :default="'All Drives'"
              class="select"
              @change-foo="changeDrives"

            /> -->
            <InputSelect
              labeltext="Color"
              :options="AllColors"
              :default="'All Colors'"
              class="select"
              @change-foo="changeColor"

            />
               <InputSelect
              labeltext="Engine"
              :options="AllEngine"
              :default="'All Engines'"
              class="select"
              @change-foo="changeEngine"

            />
          </div>
          <div>
          </div>
        </div>  
      </div>
    </Transition>
  </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { adobeAnalyticCode } from "../../analytics.js";

export default {
   props:{
   
   },
   
   components: {
    DoubleRangeSlider
  },
    computed: {...mapGetters([

    "allMakes","allBodys","AllModels","AllTransmission","AllColors","AllEngine","allSearch"
    
  ]),
   price: {
    get () {

      return this.$store.state.obj.price
    },
    set (value) {
      this.$store.commit('setSearch', value)
    }
  }
  },
  methods:{...mapActions(["setSearchValue", "refreshData"]),


  
  changepricerange(min, max){
      // console.log(min)
      // console.log(max)
      // this.setSearchValue(["price",counterValue])
      // this.refreshData();

    },

    changeprice(counterValue){
      this.setSearchValue(["price",counterValue])
      this.refreshData();

    }
,
    changeMileage(counterValue){
      this.setSearchValue(["mileage",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "mileage",
        'field_value': counterValue,
      });
      this.refreshData();
      
    }

    ,
    changeYear(counterValue){
      this.setSearchValue(["year",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "year",
        'field_value': counterValue,
      });
      this.refreshData();

    }
    ,
    changeMake(counterValue){
      // console.log(counterValue)/
      this.setSearchValue(["make",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "make",
        'field_value': counterValue,
      });
      this.refreshData();
    }
    ,
    changeModel(counterValue){
      this.setSearchValue(["model",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "model",
        'field_value': counterValue,
      });
      this.refreshData();

    }
        ,
         changeBody(counterValue){
      this.setSearchValue(["body",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "body",
        'field_value': counterValue,
      });
      this.refreshData();

    }
        ,
    changeTransmission(counterValue){
      this.setSearchValue(["transmission",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "transmission",
        'field_value': counterValue,
      });
      this.refreshData();

    }

    ,
    changeDrives(counterValue){
      this.setSearchValue(["drives",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "drives",
        'field_value': counterValue,
      });
      this.refreshData();

    }

    ,
    changeColor(counterValue){
      this.setSearchValue(["color",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "color",
        'field_value': counterValue,
      });
      this.refreshData();

    }

    ,
    changeEngine(counterValue){
      this.setSearchValue(["engine",counterValue])
      adobeAnalyticCode({
        'field_type': "vehicle-filter",
        'field_name': "engine",
        'field_value': counterValue,
      });
      this.refreshData();

    }


  },
  data() {
    return {
      inputs:{
        sliderValue: [0, 1000000],
      },
      numParagraphs: 10,
      min: 0,
      max: 10,
      isHidden: true,
    };
  },
};
</script>

<style scoped>

.selectInputs{
  margin-top: 2em;
}
.searchSlider {
  display: flex;
}
.searchSlider .range {
  width: 31%;
}
.inputGroup {
  display: flex;
  flex-direction: row;
}
.selectInputs .inputGroup{
  margin-bottom: 1.2em;
}

@media (min-width: 768px) and (max-width: 979px) {
  .inputGroup {
    /* display: flex; */
    flex-direction: column;
  }
}
.select {
  width: 100%;
  padding-right: 20px;
}
.searchBtn {
  background: #eee;
  color: #000;
  width: 30%;
  padding: 12px 14px 12px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  /* font-size: inherit; */
  line-height: 1em;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  text-align: left;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Nissan Light";
}


.searchBtn svg{
  fill: #c3002f;
  font-size: 18px;
}
.mutedText{
      font-size: 13px;
    font-family: "Nissan Bold";
    text-align: left;
    margin-bottom: 3px;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .searchBtn {
    width: 100%!important;
  }

  .inputGroup {

    flex-direction: column;
  }

}

</style>